import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AuthenticationAuthorizationTypeEnum, TwoFactorRequiredTypeEnum } from '../../../backend_api/models';
import { DEFAULT_PASSWORD_LENGTH } from '../../../base/config';
import { isUndefinedOrNullOrEmptyString, preventDefaultAndStopPropagation, twMerge, validateEmail } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { clearUserExistData, generate2FASecret, login as loginAction, logout, requestNewPassword as requestNewPasswordAction, resetPassword, setIsAuthenticated, twoFactorLogin } from '../authenticationSlice';
import { getAuthInfoSelector, getAuthenticated, getAuthenticationList, getResetPasswordInfoSelector } from '../selectors';
import { LoginCreds } from '../types';
import LoginBox from './LoginBox2';
import ResetBox from './ResetBox';
import SelectOrganisation from './SelectOrganisation';
import TwoFactorInput from './TwoFactorInput';
import TwoFactorResetContactSupport from './TwoFactorResetContactSupport';
import TwoFactorResetRequired from './TwoFactorResetRequired';
import TwoFactorSetup from './TwoFactorSetup';


type Props = {
    className?: string;
    type?: 'login' | 'reset';
};
export type TwoFactorResetProcedure = 'contact_support_required' | 'reset_required' | 'reset_password_then_setup_two_factor' | 'two_factor_reset_password' | 'reset_password';

const Login = (props: Props): React.ReactElement => {
    const { className, type = 'login' } = props;
    const authenticationList = useSelector(getAuthenticationList);
    const authInfo = useSelector(getAuthInfoSelector);
    const resetInfo = useSelector(getResetPasswordInfoSelector);
    const intl = useIntl();
    const location = useLocation();
    const locationParsed = qs.parse(location.search);
    const dispatch = useAppDispatch();
    const history = useHistory();

    type ResetState = {
        okToReset: boolean,
        emailValid: boolean,
        justReset: boolean,
        email: string,
        password: string,
        confirmPassword: string,
        resetToken: string,
        statusOk: boolean,
        emailSent: boolean,
        twoFactorEnabled: boolean,
        organizationName: string;
        twoFactorCode: string;
        twoFactorResetProcedure: string;
        newUser: boolean;
        organisationId: string;
        errors?: {
            dirty: boolean;
            pw: { ok: boolean, message: string },
            pwConfirm: { ok: boolean, message: string },
        }
    }
    const getResetState = () => {
        return {
            okToReset: false, emailValid: false, justReset: false, email: '', password: '', confirmPassword: '', resetToken: null, statusOk: true, emailSent: false, twoFactorEnabled: false,
            organizationName: undefined, twoFactorCode: undefined, twoFactorResetProcedure: undefined, newUser: undefined, organisationId: undefined,
            errors: {
                dirty: false,
                pw: { ok: false, message: '' },
                pwConfirm: { ok: false, message: '' },
            }
        };
    }
    const resetOk = () => {
        dispatch(setIsAuthenticated(true));
        reset.newUser ? history.push('/training_videos') : history.push('/');
    }

    const isAuthenticated = useSelector(getAuthenticated);
    const [login, setLogin] = useState<LoginCreds>({ email: '' });
    const [reset, setReset] = useState<ResetState>(getResetState());

    const handleTokenChanged = (token: string): void => {
        setReset({
            ...reset,
            twoFactorCode: token,
        })
    }

    const handleTokenDone = (token: string): void => {
        setLogin({
            ...login,
            token,
            email: login.email,
            password: login.password,
        });
        dispatch(twoFactorLogin({ email: type === 'login' ? login.email : reset.email, password: type === 'login' ? login.password : reset.password, orgId: type === 'reset' && reset.organisationId, token }))
    }

    const comparePw = () => {
        return (reset.confirmPassword === reset.password) && !isUndefinedOrNullOrEmptyString(reset.password)
    }

    const handleResetPasswordInput = (password: string, isConfirm: boolean) => {
        const name = isConfirm ? 'confirmPassword' : 'password';
        setReset({
            ...reset,
            [name]: password,
        })
    }

    const handleResetEmail = (email: string) => {
        setReset({
            ...reset,
            emailValid: validateEmail(email),
            email,
        })
    }

    const handleSendResetEmail = () => {
        if (!reset.okToReset && !reset.resetToken === null) {
            return true;
        }
        if (reset.resetToken === null) {
            dispatch(requestNewPasswordAction(reset.email));
            setReset({
                ...reset,
                emailSent: true,
            })
        }
    }
    const handleSumbitResetPasswords = () => {
        setTimeout(() => {
            dispatch(resetPassword({ password: reset.password, resetToken: reset.resetToken, twoFactorToken: isTwofactorEnabled() && reset.twoFactorCode }));
            setReset({
                ...reset,
                justReset: true,
            })
        }, 10)
    }

    const pwRequirements = (password: string): boolean => {
        // Other requirements go here 
        return password.length >= DEFAULT_PASSWORD_LENGTH;
    }

    const handleLoginInput = (name: string, value: string): void => {
        setLogin({
            ...login,
            [name]: value,
        });
    }

    const handleLoginSubmit = (e) => {
        preventDefaultAndStopPropagation(e);
        if (authInfo.type === AuthenticationAuthorizationTypeEnum.Authorization || authInfo.status === 'TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED') {
            dispatch(loginAction({ email: login.email, password: login.password, orgId: '', token: login.token }));
        } else {
            dispatch(twoFactorLogin({ email: login.email, password: login.password, orgId: authInfo.currentOrg || null, token: login.token }))
        }
    }

    const clearResetState = () => {
        setReset({ ...getResetState() });
    }

    const resetLogin = () => {
        dispatch(logout());
    }

    useEffect(() => {
        const errorMessage = intl.formatMessage({ id: 'login.enter_new_password.requirements.error_message_len' }, { minLen: DEFAULT_PASSWORD_LENGTH })
        const pwReq = pwRequirements(reset.password);
        const pwReqConfirm = pwRequirements(reset.confirmPassword);
        const twoFactorRet = isTwofactorEnabled() ? !isUndefinedOrNullOrEmptyString(reset.twoFactorCode) : true;
        const pwSame = comparePw();
        const errors = {
            pw: { ok: pwReq, message: errorMessage },
            pwConfirm: { ok: pwReqConfirm, message: errorMessage },
            dirty: true,
        }
        const okToReset = (errors.pw.ok && errors.pwConfirm.ok) && twoFactorRet && pwSame;
        setReset({
            ...reset,
            okToReset,
            errors,

        })
    }, [reset.password, reset.confirmPassword, reset.twoFactorCode]);

    useEffect(() => {
        const statusOk = Boolean(resetInfo.status !== 400 && resetInfo.status !== 499 && resetInfo.status !== 401);
        setReset({
            ...reset,
            statusOk,
        })
    }, [resetInfo.status]);

    useEffect(() => {
        if (type === 'reset') {
            const locationParsed = qs.parse(location.search);
            if (locationParsed.reset_token) {
                setReset({
                    ...reset,
                    resetToken: locationParsed.reset_token as string,
                    twoFactorEnabled: (locationParsed.two_factor_enabled as string) === 'true',
                    organizationName: locationParsed.organization_name as string,
                    twoFactorResetProcedure: locationParsed.procedure as TwoFactorResetProcedure,
                    newUser: locationParsed.procedure === undefined,
                    organisationId: locationParsed.organization_id as string,
                    email: locationParsed.email as string || locationParsed.user_email as string,
                });
            }
        }
    }, [location.search]);
    useEffect(() => {
        if (reset.justReset && reset.twoFactorResetProcedure === 'reset_password_then_setup_two_factor') {
            dispatch(generate2FASecret({ email: reset.email, password: reset.password, organization_id: reset.organisationId }));

        }
    }, [reset.justReset]);

    const tfSetup = <TwoFactorSetup
        handleTokenDone={handleTokenDone}
        loginFailed={authInfo.twoFactorLoginFailed}
        loginSucceded={authInfo.loginSucceded}
        isFetching={authInfo.isFetching}
        organisationName={authInfo.currentOrgName}
    />;

    const tfInput = <TwoFactorInput
        disabled={!(login && login['token'])}
        loginFailed={authInfo.twoFactorLoginFailed}
        loginSucceded={authInfo.loginSucceded}
        isFetching={authInfo.isFetching}
        handleTokenDone={handleTokenDone}
        organisationName={authInfo.currentOrgName}
        resetLogin={resetLogin}
    />;

    const tfReset = <TwoFactorResetRequired organisationName={authInfo.currentOrgName} resetLogin={resetLogin} />;

    const orgSelector = <SelectOrganisation authenticationList={authenticationList} />;

    const loginDialog = <LoginBox
        handleLoginInput={handleLoginInput}
        isAuthenticated={isAuthenticated}
        isFetching={authInfo.isFetching}
        email={login && login['email']}
        user={undefined}
        handleLoginSubmit={handleLoginSubmit}
        loginFailed={authInfo.loginFailed}
        disabled={!(login && (login['email'] && login['password']))}
    />;

    const clearUserData = (): void => {
        setReset({
            ...reset,
            okToReset: false, emailValid: false, justReset: false, email: '', password: '', confirmPassword: '', resetToken: null, statusOk: true, twoFactorResetProcedure: '',
        })
        dispatch(clearUserExistData());
    }
    const isTwofactorEnabled = () => reset.twoFactorResetProcedure === 'reset_password_then_setup_two_factor' ? false : reset.twoFactorEnabled;

    const resetBox = <ResetBox
        resetToken={reset.resetToken}
        okToReset={reset.okToReset}
        handleChange={() => null}
        handleResetEmail={handleResetEmail}
        handleResetPasswordInput={handleResetPasswordInput}
        handleSendResetEmail={handleSendResetEmail}
        handleSumbitResetPasswords={handleSumbitResetPasswords}
        handleTokenChanged={(token) => handleTokenChanged(token)}
        isAuthenticated={isAuthenticated}
        isFetching={authInfo.isFetching}
        message={'message'}
        email={reset.email}
        statusOk={reset.statusOk}
        justReset={reset.justReset}
        clearUserData={clearUserData}
        emailValid={reset.emailValid}
        emailSent={reset.emailSent}
        clearResetState={clearResetState}
        twofactorEnabled={isTwofactorEnabled()}
        organizationName={reset.organizationName}
        resetOk={resetOk}
        errors={reset.errors}
    />;

    let ret;
    if (!isAuthenticated) {
        if (type === 'reset') {
            if (locationParsed.status === 'reset_password_then_setup_two_factor_ok') {
                return resetBox;
            }
            if (reset.twoFactorResetProcedure === 'contact_support_required' || reset.twoFactorResetProcedure === 'reset_required') {
                return <TwoFactorResetContactSupport resetLogin={resetLogin} organizationName={reset.organizationName} clearUserData={clearUserData} twoFactorResetProcedure={reset.twoFactorResetProcedure} />
            }
            if (reset.justReset && reset.twoFactorResetProcedure === 'reset_password_then_setup_two_factor') {
                return <>
                    {tfSetup}
                </>
            }

            return resetBox;
        }
        if (authInfo.selectOrganisation) {
            ret = orgSelector;
        } else {
            if (authInfo.type === TwoFactorRequiredTypeEnum.TwoFactorRequired) {
                if (authInfo.status === 'TWO_FACTOR_RESET_REQUIRED') {
                    ret = tfReset;
                }
                if (authInfo.status === 'TWO_FACTOR_SETUP_REQUIRED') {
                    ret = tfSetup;
                }
                if (authInfo.status === 'TWO_FACTOR_TOKEN_REQUIRED') {
                    ret = tfInput;
                }
                if (authInfo.status === 'TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED') {
                    ret = loginDialog;
                }

            } else {
                ret = loginDialog;
            }
        }
    }
    if (isAuthenticated && type === 'reset') {
        ret = resetBox;
    }
    return <div className={twMerge('flex justify-center mb-16 sm:mb-2', className)}>
        {ret}
    </div>
}
export default Login;

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Input from '../../../base/components/basic/Input';
import { Sizes } from '../../../base/components/types';
import { useCheckHasPermission, useTranslation } from '../../../base/hooks';
import { getPermissionsSelector } from '../../../base/selectors';
import { byId } from '../../../base/types';
import Button from '../../../base/ui/components/buttons/Button';
import Chip, { ChipAddButton } from '../../../base/ui/components/chips/Chip';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import { addArrayItemOrRemoveIfExists } from '../../../base/utils';
import { Category, DetailedTag, TagAsList, TagAsTree } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { addComplianceTag, complianceRemoveTag } from '../complianceSlice';
import { isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceAddAndDeleteTag from './ComplianceAddAndDeleteTag';
import ComplianceSelectTag from './ComplianceSelectTag';
import ComplianceTag from './ComplianceTag';
import { addOrRemoveTag } from './ComplianceTagsBrowser';

type Props = {
    category: Category,
    tags: TagAsList[],
    onSelected: (tag: TagAsList, data?: any) => void,
    onlyShowLast: boolean,
    isLast: boolean,
    showSelector?: boolean;
    disabled?: boolean;
    index: number;
    downwardPropagation: boolean /* when creating requirement, selected tag is propagated downward, otherwise when creating product, it is propagated upward */
    disabledWhenInProgress?: boolean;
}

type SearchedCategory = {
    id: string;
    name: string;
    tags: Array<SearchedTagAsTree>;
    visible: boolean;
}

type SearchedTagAsTree = {
    category_id: string;
    children: Array<SearchedTagAsTree>;
    name?: string;
    path?: Array<string>;
    visible: boolean;
    tag_id?: string;
}

const ComplianceCategorySelector = (props: Props): React.ReactElement => {
    const { category, tags, onSelected, onlyShowLast, downwardPropagation, index, isLast, disabledWhenInProgress = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const inputRef = useRef(null);
    const permissions = useSelector(getPermissionsSelector);
    const isFetching = useSelector(isFetchingSelector);
    const userCanEditTags = useCheckHasPermission(permissions, 'u_compliance_edit_tags');
    const [selected, setSelected] = useState([]);
    const [expandedTags, setExpandedTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [showNewTag, setShowNewTag] = useState('');
    const [showSelector, setShowSelector] = useState(props.showSelector || false);
    const [searchString, setSearchString] = useState<string | null>(null);
    const searchedCategory: SearchedCategory = search([category], searchString)[0];
    const [inProgress, setInProgress] = useState(isFetching);
    const getTagsData = () => {
        const obj = {};
        tags && tags.forEach((tag) => {
            if (obj[tag.category_id]) {
                obj[tag.category_id].push(tag);
            } else {
                obj[tag.category_id] = [tag];
            }
        });
        return obj;
    }
    useEffect(() => {
        setSelectedData(getTagsData());
    }, [tags])
    const [selectedData, setSelectedData] = useState<byId<DetailedTag[]>>(getTagsData());
    const placeholder = category.tags.length > 0 ? `${intl.formatMessage({ id: 'compliance.category_selector.example_abbreviation' })} ${category.tags[0].name}${category.tags[1] ? `, ${category.tags[1].name}` : ''}` : intl.formatMessage({ id: 'compliance.category_selector.type' });
    const selectedTagKeys = selectedData && Object.keys(selectedData);
    let level = 0;

    const removeTag2 = (tag: DetailedTag) => {
        dispatch(complianceRemoveTag({ category_id: tag.category_id, tag_id: tag.tag_id }));
    }
    const updateNewTag = (tag: string) => {
        setNewTag(tag);
    };

    const addTag = (categoryId: string, tag_id: string) => {
        if (newTag) {
            dispatch(addComplianceTag({ categoryId, parent_tag_id: tag_id, tag_name: newTag }));
            setShowNewTag('');
            setNewTag('');
        }
    };

    const showAddNewTag = (tag: DetailedTag, level: number, isOpen?: boolean) => {
        const isBase = tag.path.length === 0;
        const addToName = isBase ? tag.category_name : tag.path[tag.path.length - 1];
        const addNewTag = showNewTag === (isBase ? tag.category_id : getTagsId(tag.path));
        return <div className=''>
            {addNewTag && isOpen && <div className=' w-full'>
                <form className='flex flex-col gap-1 py-2' onSubmit={() => {
                    addTag(tag.category_id, tag.tag_id);
                }}>
                    <Input autoFocus className='text-xs pt-2 pb-2' type='text' onChange={(e) => updateNewTag(e.currentTarget.value)} placeholder={intl.formatMessage({ id: 'compliance.category_selector.enter_new_tag' })} />
                    {userCanEditTags && <div className='flex gap-2'>
                        <Button disabled={newTag === ''} size={Sizes.XSmall} className='' secondary onPress={() => addTag(tag.category_id, tag.tag_id)}><FormattedMessage id='compliance.category_selector.save_new_tag' /></Button>
                        <Button size={Sizes.XSmall} className='' onPress={() => setShowNewTag('')}><FormattedMessage id='globals.cancel' /></Button>
                    </div>}
                </form>
            </div>}
            {!addNewTag && <div className='invisible group-hover:visible flex'>
                {userCanEditTags && <Popup trigger={<Button className='text-xs py-1 px-2' size={Sizes.Small} onPress={() => setShowNewTag(isBase ? tag.category_id : getTagsId(tag.path))}><FormattedMessage id='compliance.category_selector.add_tag' /></Button>}><FormattedMessage id='compliance.tags.add_sub_tag_to_tag' values={{ tag: <span className='font-bold'>{addToName}</span> }} /></Popup>}
                {!isBase && userCanEditTags && <Popup trigger={<span><DeleteConfirm type='alert'
                    alertText={<span>{intl.formatMessage({ id: 'compliance.tags.remove_tag_warning_text' }, { tagName: tag.name }).toString()}</span>}
                    alertHeader={<span>{intl.formatMessage({ id: 'compliance.tags.remove_tag_warning_header' }, { tagName: tag.name })}</span>}
                    deleteFunction={() => removeTag2(tag)}
                    trigger={
                        <Icon name='operation_delete' />
                    } /></span>}><FormattedMessage id='compliance.tags.remove_tag' values={{ tagName: <span className='font-bold'>{tag.name}</span> }} /></Popup>
                }
            </div>}
        </div>
    };

    const addExpandedTag = (tagId: string) => {
        const _expandedTags = [...expandedTags];
        setExpandedTags(addArrayItemOrRemoveIfExists(_expandedTags, tagId));
    };

    const removeTag = (index: number, t: DetailedTag) => {
        const _data = { ...selectedData };
        const remaining = _data[t.category_id].filter((tag) => tag.path.toString() !== t.path.toString())
        const _selected = selected.filter((s, i) => i != index);
        _data[t.category_id] = remaining;
        setSelected(_selected);
        setSelectedData(_data);
        onSelected(t);
    };

    const getIsTagSelected = (tag: DetailedTag): boolean => {
        let selected = false;
        selectedData[tag.category_id] && selectedData[tag.category_id].forEach((t) => {
            if ((t.tag_id && t.tag_id.toString() === tag.tag_id.toString()))  {
                selected = true;
            }
        });
        return selected;
    }

    const getTagHasSelected = (tag: TagAsTree, treshold: number): DetailedTag[] => {
        return selectedData[tag.category_id] && selectedData[tag.category_id].filter((t => t?.path?.includes(tag.name) && t?.path?.[0] === tag.name && t?.path?.length > treshold));
    };

    const getCategoryHasSelected = (cat: Category) => {
        return selectedData[cat.id] && selectedData[cat.id].filter((t => t?.path?.length > 0))
    };

    const setData = (item: DetailedTag, checked: boolean, level) => {
        const data = { ...selectedData };
        const categoryId = item.category_id;
        let path = [];
        // Tag
        if (data[categoryId]) {
            if (checked) {
                // Remove
                let index = -1;
                data[categoryId].forEach((tag, i) => {
                    if (tag.path.toString() === item.path.toString()) {
                        index = i;
                    }
                });
                if (index > -1) {
                    data[categoryId].splice(index, 1);
                }
            } else {
                // Add
                // First, remove any selected tags below current add level
                let i = data[categoryId].length;
                while (i--) {
                    if (data[categoryId][i].path?.length > level) {
                        data[categoryId].splice(i, 1);
                    }
                }
                path = item.path;
                data[categoryId].push({ category_id: categoryId, path, tag_id: item.tag_id, category_name: item.category_name, name: item.name });
            }
        } else {
            data[categoryId] = [];
            data[categoryId].push({ category_id: categoryId, path: item.path, tag_id: item.tag_id, category_name: item.category_name, name: item.name });
        }
        setSelectedData(data);
    };

    const selectTag = (tag: any, checked: boolean, level: number) => {
        disabledWhenInProgress && setInProgress(true);
        setData(tag, checked, level);
        const _selected = addOrRemoveTag([...selected], {
            category_id: tag.category_id,
            tag_id: tag.tag_id
        });
        setSelected(_selected);
        onSelected && onSelected({
            category_id: tag.category_id,
            tag_id: tag.tag_id,
        }, tag);
        // preventDefaultAndStopPropagation(e);
    };

    const renderTags = (tags: DetailedTag[], parentSelected): React.ReactElement[] => {
        level++;
        return tags && tags.map((tag) => {
            return renderTagItem(tag, parentSelected, level)
        });
    }

    const renderTagItem = (tag, parentSelected: boolean, level: number) => {
        const isTagSelected = getIsTagSelected(tag);
        const selectedTagsOnTag = getTagHasSelected(tag, 1);
        const hasSubCnt = selectedTagsOnTag?.length;
        let checked = false;
        let disabledFromProps = props.disabled;
        let disabled = false;
        if (downwardPropagation) {
            checked = isTagSelected || parentSelected;
            disabled = checked && parentSelected;
        } else {
            checked = isTagSelected || hasSubCnt > 0;
            disabled = isTagSelected ? false : hasSubCnt > 0;
        }

        const visibleChildren = tag.children.filter(function (t: any) {
            if (t.visible === undefined) {
                return true;
            } else {
                return t.visible
            }
        })

        const isExpanded = expandedTags.includes(getTagsId(tag.path)) || searchString;
        return <>
            <ComplianceSelectTag
                tag={tag}
                tagsId={getTagsId(tag.path)}
                selectedTagsOnTag={selectedTagsOnTag}
                subCnt={0}
                selectTag={selectTag}
                level={level}
                addExpandedTag={addExpandedTag}
                isExpanded={isExpanded as boolean}
                checked={checked}
                disabled={inProgress || disabled || disabledFromProps}
                onAddTag={(catId, tagId) => {
                    setNewTag(tagId); addTag(catId, tagId)
                }}
                removeTag={removeTag2}
                updateNewTag={updateNewTag}
            />
            {isExpanded && visibleChildren.length > 0 && <div className='pl-8'>{renderTags(visibleChildren, checked)}</div>}
        </>
    }

    level = 0;
    const isSelected = false;
    const hasTags = searchedCategory.tags.length > 0;
    const trigger = <ComplianceAddAndDeleteTag updateNewTag={updateNewTag} onAddTag={addTag} showInput show={false} onShow={() => setShowNewTag('')} tag={{ category_id: category.id, category_name: category.name, path: [], tag_id: null, name: '' }} deleteFunction={() => null} isBase={true} />;
    useEffect(() => {
        if (isFetching !== inProgress) {
            setInProgress(isFetching);
        }
    }, [isFetching])
    return (
        <div key={'cat_' + category.id} className={twMerge('border border-b-0 p-3', index === 0 && 'rounded-t-md', isLast && 'rounded-b-md border-b')}>
            <div className='text-sm text-secondary pb-2'>{category.name}</div>
            {showSelector && <div className={twMerge('flex gap-x-1 items-center', selectedTagKeys.keys.length > 0 ? ' pb-4' : ' pb-2')}>
                <Field type='text'
                    className={'w-full'}
                    onChange={(value) => setSearchString(value)}
                    placeholder={intl.formatMessage({ id: 'compliance.category_selector.filter_tags' })}
                    onFocus={() => setShowSelector(true)}
                    autoFocus
                />
                <Button className='' onPress={() => {
                    setShowSelector(false);
                    setSearchString(null);
                }} size={Sizes.Small} secondary><FormattedMessage id='compliance.category_selector.done' /></Button></div>}
            <div>
                <div className={''}>
                    <div className='flex flex-row' onClick={(event) => {
                        inputRef.current && inputRef.current.focus();
                        event.stopPropagation();
                    }}>
                        <div className='flex flex-wrap items-center gap-2'>
                            {selectedTagKeys.length === 0 && <Chip empty={true} size={Sizes.Small} label={intl.formatMessage({ id: 'compliance.product.tags_browser.no_tags_selected' })} />}
                            {selectedTagKeys.map((tag) => {
                                return (selectedData[tag] && selectedData[tag].filter(tag => tag.category_id == category.id).map((t, i) => {
                                    return <ComplianceTag key={'tag_' + t.category_id + t.tag_id + i} tag={t} removeTag={() => removeTag(i, t)} index={i} isEditing onlyShowLast={onlyShowLast} disabled={props.disabled} />
                                }))
                            })}
                            {!showSelector && <ChipAddButton size={Sizes.Small} onAdd={() => setShowSelector(true)} />}
                        </div>
                    </div>
                    <div>
                        {showSelector && searchedCategory &&
                            <div className={twMerge('flex items-center')} key={category.id}>
                                <div className='flex-col w-full pt-2'>
                                    {renderTags(searchedCategory.tags.filter(t => t.visible) as unknown as DetailedTag[], isSelected)}
                                    {userCanEditTags && <div className='justify-between flex w-full items-center cursor-pointer'>
                                        {hasTags && !showNewTag ? trigger : <Popup trigger={<span>
                                            {trigger}</span>}>
                                            {useTranslation('compliance.category_selector.add_sibling_tag_popup_text', { tag: category.name })}
                                        </Popup>}

                                    </div>}

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComplianceCategorySelector;

function getTagsId(tags: string[]): string {
    if (tags) {
        return 'tag_' + tags.join('_');
    }
    return '';
}

function tagMatches(tag: { name?: string }, searchString: string): boolean {
    if (!searchString) {
        return false;
    }
    if (searchString == '') {
        return true;
    }
    if (typeof tag.name == 'string') {
        return tag.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
    }
    return false;
}

/**
 * After calling this function, every tag and category that have a matching descendant (or match themselves)
 * will have set `visible` to `true`
 */
function searchCategory(category: Category, searchString: string): SearchedCategory {
    const searchedTags = (category.tags || []).map(function (tag: TagAsTree) {
        return searchTagAsTree(tag, searchString);
    });
    const shouldBeVisible = searchedTags.some(function (t) { return t.visible });
    return {
        ...category,
        tags: searchedTags,
        visible: shouldBeVisible
    }
}

function searchTagAsTree(tag: TagAsTree, searchString: string): SearchedTagAsTree {
    const searchedTags = (tag.children || []).map(function (tag: TagAsTree) {
        return searchTagAsTree(tag, searchString);
    });
    const shouldBeVisible = searchedTags.some(function (t) { return t.visible }) || tagMatches(tag, searchString);
    return {
        ...tag,
        visible: shouldBeVisible,
        children: searchedTags
    }
}

function markCategoryVisible(category: Category): SearchedCategory {
    return {
        ...category,
        visible: true,
        tags: (category.tags || []).map(markTagVisible)
    }

}

function markTagVisible(tag: SearchedTagAsTree | TagAsTree): SearchedTagAsTree {
    return {
        ...tag,
        visible: true,
        children: (tag.children || []).map(markTagVisible)
    }
}

function search(categories: Category[], searchString: string): SearchedCategory[] {
    if (searchString === null || searchString === undefined || searchString == '') {
        return categories.map(function (category) {
            return markCategoryVisible(category as SearchedCategory);
        })
    } else {
        return categories.map(function (category) {
            return searchCategory(category as SearchedCategory, searchString)
        });
    }
}